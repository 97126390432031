<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">直播管理</a>
          <i>></i>
          <a href="javascript:history.back();">我的直播</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">直播状况</a>
        </span>
      </div>
      <div class="framePage-halfBody">
        <div class="framePage-body">
          <div
            style="width: 100%; height: 700px; position: relative"
            id="disv"
            v-show="playing"
          >
            <div class="prism-player" id="J_prismPlayer"></div>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              height: 100%;
            "
            v-show="!playing"
          >
            <img src="../../assets/videoEmpty.png" />
            <p style="font-size: 1rem; color: #ccc">开启您的精彩直播</p>
          </div>
        </div>
        <div class="framePage-article">
          <div class="ovy-a">
            <div>
              <div>
                <el-tabs v-model="activeName" :stretch="true">
                  <el-tab-pane label="基本信息" name="basic">
                    <el-form>
                      <el-form-item label="直播课节:">{{
                        basicInfo.liveLessonName
                      }}</el-form-item>
                      <el-divider></el-divider>
                      <el-form-item label="直播讲师:">{{
                        basicInfo.teacherNames
                      }}</el-form-item>
                      <el-divider></el-divider>
                      <el-form-item label="直播时间:"
                        >{{ basicInfo.startTime }} -
                        {{ basicInfo.endTime }}</el-form-item
                      >
                      <el-divider></el-divider>
                      <el-form-item label="直播课程:">{{
                        basicInfo.liveCourseName
                      }}</el-form-item>
                      <el-divider></el-divider>
                      <el-form-item label="直播状态:">{{
                        $setDictionary("LIVE_STATE", basicInfo.liveState)
                      }}</el-form-item>
                      <el-divider></el-divider>
                      <el-form-item label="直播参数:">{{
                        basicInfo.liveParam
                      }}</el-form-item>
                      <el-button @click="reload">刷新</el-button>
                      <el-button
                        @click="playRecord"
                        v-show="basicInfo.liveState == '30'"
                        >查看回放</el-button
                      >
                      <el-button
                        @click="playAlive"
                        v-show="basicInfo.liveState == '20'"
                        >查看直播</el-button
                      >
                      <el-button
                        @click="privacyDialogShow = true"
                        v-show="basicInfo.liveState == '10'"
                        >开始直播</el-button
                      >
                      <el-button
                        @click="startLive(false)"
                        v-show="basicInfo.liveState == '20'"
                        >结束直播</el-button
                      >
                      <el-divider></el-divider>
                      <el-button @click="chooseEmitQuestion" v-show="basicInfo.liveState == '20'">弹题</el-button>
                      <el-button @click="uploadFile()" v-show="basicInfo.liveState == '20'">上传附件</el-button>
                    </el-form>
                  </el-tab-pane>
                  <el-tab-pane label="学员列表" name="student">
                    <div v-for="stu in students" :key="stu">
                      <span
                        >{{ stu.name }} | {{ stu.mobile }} -
                        {{ onlineFormat(stu.online) }}</span
                      >
                      <el-divider></el-divider>
                    </div>
                    <el-button @click="showDialog">查看学员更多信息</el-button>
                  </el-tab-pane>
                  <el-tab-pane label="交流问答" name="message">
                    <div>
                      <span>老师分享了文件</span>
                    </div>
                    <div v-for="(item, index) in uploadFiles" :key="index">
                      <span>{{ item.fileName }}</span>
                      <span
                        style="color: #5bb5ff; margin: 0 15px; cursor: pointer"
                        @click="lookPdf(item.fileUrl)"
                        >查看</span
                      >
                    </div>
                    <el-divider></el-divider>
                    <div v-for="msg in messages" :key="msg">
                      <span>{{ msg.name }} - {{ msg.createTime }}</span>
                      <br />
                      <span>{{ msg.message }}</span>
                      <el-divider></el-divider>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogShow" title="学员列表">
      <el-table :data="students" border>
        <el-table-column
          label="学员姓名"
          prop="name"
          resizable
          align="center"
        ></el-table-column>
        <el-table-column
          label="进入时间"
          prop="enterTime"
          resizable
          align="center"
        ></el-table-column>
        <el-table-column
          label="离开时间"
          prop="exitTime"
          resizable
          align="center"
        ></el-table-column>
        <el-table-column
          label="累计在线时长"
          prop="duration"
          resizable
          align="center"
          :formatter="durationFormat"
        ></el-table-column>
        <el-table-column
          label="当前是否在线"
          prop="online"
          resizable
          align="center"
          :formatter="onlineFormat"
        ></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :visible.sync="privacyDialogShow">
      <div>
        <h3 style="text-align: center">安知职业培训平台直播服务协议</h3>
        <pre style="white-space: pre-wrap">
  为更好地向您提供服务，请您务必在使用安知职业培训平台（以下简称“平台”）提供的直播服务前，认真阅读并充分理解《安知职业培训平台直播服务协议》及其附件（以下简称本协议）的全部内容，特别是涉及免除或者责任限制条款等内容。其中，免除或者责任限制条款等重要内容将以加粗形式提醒您注意，您应重点阅读。如果您对本协议的任何条款存在异议，或者无法准确理解本协议，您应立即停止使用本公司提供的直播服务；<b>您勾选“我同意”按钮，则视为同意并接受本协议全部条款，自愿服从本平台统一管理。</b>在您同意本协议之后，本平台可能因国家政策、平台规则以及履行本协议的客观情况发生变化而修改本协议，修改后的协议将发布到本平台上。若您对修改后的协议有异议，请立即停止使用本平台的直播功能，如您继续使用本平台进行直播，则视为您同意本协议的修改。
<b>第一条 总则</b>
    1、您根据平台注册要求及规则，申请成为平台的直播服务提供方（以下简称“直播方”），为平台用户提供职业技能培训的直播服务，您在平台提供服务期间均应视为协议期内。晟融公司（安知职业平台应用程序及运营商，北京晟融信息技术有限公司，以下简称晟融公司）不事先审核前述被上载的、由您参与、编辑、制作的视频内容，也不主动对该等视频进行任何编辑、整理、修改、加工。
    2、签署本协议前，您已充分了解平台的各项规则及要求，且有条件及有能力、资格履行本协议约定的直播方职责及义务。本协议对您构成有效的、带有约束力的、可强制执行的法定义务，您对本协议下所有条款及定义等内容均已明确知悉，并无疑义。
    3、您承诺并声明在为平台用户提供服务时符合所在地法律的相关规定，不得以履行本协议名义从事其他违反中国及所在地法律规定的行为。
    4、您与晟融公司不构成任何劳动法律层面的雇佣、劳动、劳务关系。
<b>第二条 公司权利义务</b>
    1、晟融公司有权制定平台运营制度及对直播方的管理规则，并将其作为本协议的一部分，有权对您进行管理和监督，有权根据运营情况对相应规则做出调整或变更，您对此表示理解和同意。
    2、晟融公司有权根据法律法规的规定或者相关部门的要求对您视频直播内容的合法性进行审查。如果视频直播内容存在违法、违规、侵害晟融公司或者第三方合法权益的情形，晟融公司有权采取停播、删除内容、注销帐号、配合主管部门调查等措施。
    3、晟融公司有权对您在平台进行的视频直播所生产的视频文件进行存储保留。
    4、晟融公司负责提供视频直播平台，并提供技术服务支持。但晟融公司不担保网络服务一定能满足您的任何要求，不担保网络服务时刻通畅，不担保网络服务的及时性、安全性、准确性。
    5、鉴于网络服务的特殊性，您同意平台有随时变更、中断或者终止部分或者全部的平台服务，如变更、中断或者终止平台服务而造成任何损失，平台无需对您或任何第三方承担任何责任，平台尽量在变更、中断或终止服务之前事先以网页公告、电子邮件、手机短信或系统弹窗等形式通知您。
<b>第三条 您的权利义务</b>
<b>  您承诺不会因履行本协议损害第三方合法利益或违反中国及您所在国的法律、法规、规范性规定等，您接受并履行本协议不违反任何对您有约束力的法律文件，亦不会使晟融公司因您的任何直播、评论等使用平台的行为而对任何第三方承担任何责任。 </b>
    1、您应自行承担进行视频直播所需要的网络、支持视频和语音的设备，并自行保证直播图像和语音质量的清晰、稳定。 
    2、您在晟融公司平台提供视频直播服务和在平台上发布的所有信息、资讯、言论等均不得涉政、涉枪、涉毒、涉暴、涉黄或其他含有任何违反中国法律、法规及规范性规定的内容，包括但不限于危害国家安全、淫秽色情、虚假、诽谤、恐吓骚扰他人、侵犯他人知识产权、商业秘密或其他合法权益以及有违社会公序良俗的内容或指向上述内容的方式。<b>如您是中华人民共和国军人、警察、国家工作人员等特殊类公职人群，因人群工作的特殊性，需遵守相关部门及国家的相关规定和要求，晟融公司有权根据相关部门及国家的相关规定和要求拒绝为您提供视频直播服务。 </b>
    3、您在使用平台视频直播服务时，必须向晟融公司提供准确的个人资料，如您提供资料不实，晟融公司有权拒绝提供视频直播服务；如您个人资料有任何变动，必须及时更新并通知晟融公司进行审核。如因您提供的个人资料等不实而造成任何损失，由您自已承担全部责任和损失。 
    4、您承诺遵守诚实信用原则，不扰乱晟融公司平台的正常秩序，未经晟融公司同意不发布商业广告，不从事与在线教育直播无关的活动。 
    5、您注册或登录的帐号和密码由您自行负责设置并保管，禁止赠与、借用、租用、转让或者售卖。您应当对以您帐号进行的所有活动和事件、结果承担全部法律责任。如因黑客行为或您的保管疏忽导致帐号、密码遭他人非法盗取、使用或遭受损失，晟融公司不承担任何责任；如给晟融公司造成损害，根据晟融公司损失情况，您应予相应赔偿。 
    6、您承诺不在直播或者其他使用平台服务的过程中透露自己的各类账户、银行卡(包括信用卡)、第三方支付账户及对应密码等重要资料，也不会将涉及您隐私的信息发表、上传或通过平台服务提供给其他人，否则由此产生的责任和损失应由您自行承担。 
    7、您承诺积极维护晟融公司及平台形象，您不得做出有损于晟融公司或平台形象或利益的行为。本协议期内及协议终止后，您不会通过任何渠道以任何形式发布暗示或不利于晟融公司或平台的言论。 
    8、未经晟融公司书面同意，您不得在任何场合以任何形式(包括但不限于文字、语音、视频、图片等)提及第三方竞争平台的相关信息，不得引导晟融公司平台现有用户、其他直播方或晟融公司员工进入其他第三方竞争平台或为其提供任何服务、信息或便利。 
<b>第四条 保密条款</b>
    1、您应严格遵守晟融公司的保密制度，承诺无限期保守晟融公司或本平台的商业秘密。商业秘密是指由晟融公司提供的、或者您在双方合作期间了解到的、或者晟融公司对第三方承担保密义务的，与晟融公司业务有关的，能为晟融公司带来经济利益，具有实用性的、非公知的所有信息，包括（但不限于）：技术信息、经营信息和与晟融公司行政管理有关的信息和文件（含本协议及相关协议内容）。
    2、您应严格遵守本协议，未经晟融公司书面授权或同意，对晟融公司的商业秘密不得：
    （1）以任何方式向第三方或不特定的公众进行传播、泄露；
    （2）为非本协议的目的而使用晟融公司的商业秘密。
    3、因您违反约定使用或披露晟融公司商业秘密和信息使晟融公司遭受任何名誉、声誉或经济上的、直接或间接的损失，您应赔偿晟融公司全部损失。
    4、以上保密条款并不因本协议的解除、终止、无效而失效。
<b>第五条 违约责任</b>
    1、任何由于您声明不实或违反其声明承诺事项导致他方向晟融公司提起诉讼、索赔及/或导致晟融公司声誉受损之后果，您将承担公司因此产生的全部直接及间接费用、损失及赔偿，其中包括晟融公司为诉讼支付的有关费用及律师费。
    2、除本协议另有约定外，您违反本协议下任何规定或您提供的服务不符合晟融公司的要求，晟融公司有权单方面采取相应限制或处罚措施，包括但不限于：限制、关闭、回收、或终止您对直播服务的使用。
<b>第六条 争议处理</b>
    1、本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律。
    2、若您和晟融公司之间发生任何纠纷或争议，首先应友好协商解决；协商不成功的，双方均同意将纠纷或争议提交本协议签订地有管辖权的人民法院解决。
        </pre>
        <a
          target="_blank"
          href="https://anzhi.bjsrxx.com/banner/app_static/liveBroadcasting.html"
          >附件: 行为规范</a
        >
      </div>
      <template slot="footer">
        <el-button @click="privacyDialogShow = false">取 消</el-button>
        <el-button type="primary" @click="agreePrivacy">我同意</el-button>
      </template>
    </el-dialog>
    <el-dialog
      :visible.sync="uploadfilevisiable"
      title="上传附件"
      @close="fileClose"
    >
      <el-upload
        class="upload-demo upload-btn"
        :action="actionUrl"
        :on-error="handleError"
        :on-success="handleSuccess"
        :on-change="uploadChange"
        multiple
        :show-file-list="false"
        :auto-upload="false"
      >
        <el-button class="bgc-bv">上传附件</el-button>
      </el-upload>
      <div v-if="NameList.length" style="margin-top: 10px; margin-left: 10px">
        当前选择文件：
        <div style="display: flex; flex-direction: column">
          <span
            style="color: #409eff"
            v-for="(item, index) in NameList"
            :key="index"
          >
            {{ item.fileName }}
            <a
              @click="lookExl(item.fileUrl)"
              style="color: #5bb5ff; margin: 0 15px"
              >预览</a
            >
            <a @click="reomveExl(index)" style="color: red; margin: 0 15px"
              >删除</a
            >
          </span>
        </div>
      </div>
      <div v-else>
        <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
      </div>
      <template slot="footer">
        <el-button @click="fileClose">取 消</el-button>
        <el-button class="bgc-bv" @click="uploadSure">确定</el-button>
      </template>
    </el-dialog>
    <!-- 预览 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding"
      width="50%"
      top="2%"
      center
    >
      <div style="height: 600px" class="ovy-a">
        <div id="pdf-cert" style="height: 600px"></div>
      </div>
    </el-dialog>
    <el-dialog
      class="pztt"
      :visible.sync="answerVisible"
      title="答题统计"
      width="700px"
      top="3%"
      :center="true"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="times">计时：{{ counterTime }}</div>
      <div v-for="(question, qIndex) in answerDatas.questions" :key="qIndex" class="shell">
        <p class="title">{{ question.content }}</p>
        <p
          class="content"
          v-for="(option, oIndex) in question.options"
          :key="oIndex"
          :class="option.correct ? 'tsColor1' : ''"
        >
          <span>{{ option.content }}</span>
          <span>{{ option.count }}人</span>
          <span>{{ question.totalCount == 0 ? 0 : parseInt(option.count * 100 / question.totalCount) }}%</span>
        </p>
      </div>
      <div>
        <p>
          已答题人数<span>{{ answerDatas.answeredCount }}</span
          >人
        </p>
        <p>
          总人数<span>{{ answerDatas.userCount }}</span
          >人
        </p>
      </div>
      <template slot="footer">
        <el-button type="primary" class="bgc-bv" @click="closeEmitQuestion"
          >结束答题</el-button
        >
      </template>
    </el-dialog>
    <el-dialog
      class="pztt"
      :visible.sync="questionChosenVisible"
      title="选择弹题"
      width="700px"
      top="3%"
      :center="true"
    >
    <el-form
        label-width="20px"
        class="demo-dynamic"
        >
        <template v-for="(qItem, qIndex) in questionList">
            <el-form-item
              :key="'ti' + qIndex"
              >
              <el-checkbox v-model="qItem.chosen"></el-checkbox>
              <span style="display: inline; margin-left: 5px;">{{ qItem.content }}</span>
            </el-form-item>
            <el-divider :key="qIndex"></el-divider>
        </template>
      </el-form>
      <template slot="footer">
        <el-button type="primary" class="bgc-bv" @click="cancelQuestionChosen"
          >取消</el-button
        >
        <el-button type="primary" class="bgc-bv" @click="ensureQuestionChosen"
          >确定</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>
<script>
import pdf from "pdfobject";
export default {
  components: {},
  data() {
    return {
      activeName: "basic",
      liveCourseId: 0,
      liveLessonId: 0,
      basicInfo: {},
      msgTimer: {},
      lastMsgReqTime: 0,
      messages: [],
      stuTimer: {},
      lastStuReqTime: 0,
      students: [],
      dialogShow: false,
      privacyDialogShow: false,
      player: {},
      playing: false,
      uploadfilevisiable: false,
      NameList: [],
      uploadFiles: [],
      previewLoding: false, //预览pdf
      answerVisible: false, // 答题统计弹窗
      questionChosenVisible: false, // 弹题选择弹窗
      // 答题 - 数据
      questionList: [],
      answerDatas: {},
      counter: 0,
      answerTimer: {}
    };
  },
  created() {
    this.liveCourseId = this.$route.query.liveCourseId;
    this.liveLessonId = this.$route.query.liveLessonId;
    // 加载基础信息
    this.loadBasicInfo();
    this.getuploadList();
  },
  beforeDestroy() {
    clearInterval(this.msgTimer);
    clearInterval(this.stuTimer);
    clearInterval(t.answerTimer);
  },
  computed: {
    counterTime() {
      return (this.prefixZero(parseInt(this.counter / 60)) + ":" + this.prefixZero(this.counter % 60));
    }
  },
  watch: {
    activeName(val) {
      clearInterval(this.msgTimer);
      clearInterval(this.stuTimer);
      if (val == "message") {
        this.loadMessages();
        this.msgTimer = setInterval(this.loadMessages, 3000);
      } else if (val == "student") {
        this.loadAllStudents();
        this.stuTimer = setInterval(this.loadLastStudents, 3000);
      }
    },
    "basicInfo.streamState": function(val) {
      // 当直播状态发生变化时销毁播放器
      if (this.player.getStatus) {
        this.player.dispose();
      }
      // if (val == '30') {
      //   this.playAlive();
      // } else if (val == '60') {
      //   this.playRecord();
      // }
    },
    answerVisible(val) {
      if (val) {
        const t = this;
        this.$post("/biz/live/question/v1/statistics", { liveLessonId: this.liveLessonId }, 3000, true, 3)
          .then((ret) => {
            if (ret.ok) {
              this.answerDatas = ret.data;
            }
          })
        this.answerTimer = setInterval(() => {
          t.counter++;
          this.$post("/biz/live/question/v1/statistics", { liveLessonId: this.liveLessonId }, 3000, false, 3)
          .then((ret) => {
            if (ret.ok) {
              this.answerDatas = ret.data;
            }
          })
        }, 1000);
      } else {
        clearInterval(this.answerTimer);
      }
    }
  },
  methods: {
    prefixZero(num, n) {
      return (Array(2).join(0) + num).slice(-2);
    },
    loadBasicInfo(callback) {
      let param = {
        liveLessonId: this.liveLessonId,
      };
      this.$post("/biz/live/lesson/enterLive", param, 3000, true, 3).then(
        (re) => {
          this.basicInfo = re.data;
          if (callback) {
            callback();
          }
        }
      );
    },
    loadAllStudents() {
      let params = {
        liveCourseId: this.liveCourseId,
        liveLessonId: this.liveLessonId,
      };
      this.$post(
        "/biz/live/lesson/loadAllStudents",
        params,
        3000,
        true,
        3
      ).then((re) => {
        re.data.list = re.data.list.sort((a, b) => {
          if (a.online === b.online) {
            return a.userId - b.userId;
          } else {
            return a.online ? -1 : 1;
          }
        });
        this.students = re.data.list;
      });
    },
    loadLastStudents() {
      if (this.basicInfo.liveState == "10") {
        return;
      }
      let params = {
        liveCourseId: this.liveCourseId,
        liveLessonId: this.liveLessonId,
        lastReqTime: this.lastStuReqTime,
      };
      this.$post("/biz/live/lesson/loadStudents", params, 3000, false, 3).then(
        (re) => {
          if (re.data.list.length > 0) {
            let changedUserMap = {};
            re.data.list.forEach((user) => {
              changedUserMap[user.userId] = user.online;
            });
            this.students.forEach((stu) => {
              let online = changedUserMap[stu.userId];
              stu.online = online | stu.online;
            });
            this.students = this.students.sort((a, b) => {
              if (a.online === b.online) {
                return a.userId - b.userId;
              } else {
                return a.online ? -1 : 1;
              }
            });
          }
          //记录请求时间,用于增量查询
          this.lastStuReqTime = re.data.lastReqTime;
        }
      );
    },
    loadMessages() {
      if (this.basicInfo.liveState == "10") {
        return;
      }
      let params = {
        liveCourseId: this.liveCourseId,
        liveLessonId: this.liveLessonId,
        lastReqTime: this.lastMsgReqTime,
      };
      this.$post(
        "/biz/live/lesson/loadChatMessages",
        params,
        3000,
        false,
        3
      ).then((re) => {
        re.data.list.forEach((item) => {
          this.messages.unshift(item);
        });
        //记录请求时间,用于增量查询
        this.lastMsgReqTime = re.data.lastReqTime;
      });
    },
    playRecord() {
      if (this.basicInfo.streamState != "60") {
        this.$message.error("转码中,暂时无法查看回放");
        return;
      }
      this.$post("/sys/video/videoPlayAuth", {
        videoId: this.basicInfo.videoId,
      }).then((re) => {
        this.initPlayer({
          isLive: false,
          vid: this.basicInfo.videoId,
          encryptType: 1,
          playauth: re.data,
          skinLayout: [
            {
              name: "controlBar",
              align: "blabs",
              x: 0,
              y: 0,
              children: [
                { name: "progress", align: "blabs", x: 0, y: 44 },
                { name: "playButton", align: "tl", x: 15, y: 12 },
                { name: "timeDisplay", align: "tl", x: 10, y: 7 },
              ],
            },
          ],
        });
      });
    },
    playAlive() {
      if (this.basicInfo.streamState != "30") {
        this.$message.error("没有推流,暂时无法查看直播");
        return;
      }
      this.initPlayer({
        isLive: true,
        source: this.basicInfo.videoPlayParam,
        skinLayout: [
          {
            name: "controlBar",
            align: "blabs",
            x: 0,
            y: 0,
            children: [{ name: "playButton", align: "tl", x: 15, y: 12 }],
          },
        ],
      });
    },
    startLive(start) {
      let param = {
        liveLessonId: this.liveLessonId,
        start: start,
      };
      if (!start) {
        this.$confirm("关闭后,将不能再开启,确定关闭么?", "提示")
          .then(() => {
            this.$post(
              "/biz/live/lesson/startOrExitLive",
              param,
              3000,
              false,
              3
            ).then((re) => {
              if (re.status == "0") {
                this.$message.success("关闭成功");
                this.loadBasicInfo();
              }
            });
          })
          .catch(() => {});
      } else {
        this.loadBasicInfo((re) => {
          if (this.basicInfo.streamState != "30") {
            this.$message.error("请先开始推流");
            return;
          }
          this.$post(
            "/biz/live/lesson/startOrExitLive",
            param,
            3000,
            false,
            3
          ).then((re) => {
            if (re.status == "0") {
              this.$message.success("开启成功");
              this.basicInfo.liveState = "20";
            }
          });
        });
      }
    },
    showDialog() {
      this.dialogShow = true;
      this.loadAllStudents();
    },
    onlineFormat(val) {
      let online = val.online | val;
      return online == true ? "在线" : "离线";
    },
    durationFormat(val) {
      if (val.duration == null) {
        return "";
      }
      let seconds = parseInt(val.duration / 1000);
      let minutes = parseInt(seconds / 60);
      if (minutes > 0) {
        return minutes + "分" + (seconds % 60) + "秒";
      } else {
        return seconds + "秒";
      }
    },
    streamStateFormat(val) {
      switch (val) {
        case "10":
          return "未推流";
        case "20":
          return "未推流";
        case "30":
          return "正在直播";
        case "40":
          return "直播停止";
        case "50":
          return "录制转码中";
        case "60":
          return "录制完成";
        default: {
          return "-未知-";
        }
      }
    },
    initPlayer(params) {
      this.playing = true;
      let initOpts = {
        id: "J_prismPlayer",
        width: "100%",
        height: "100%",
        autoplay: true,
        rePlay: false,
        playsinline: true,
        preload: true,
        controlBarVisibility: "hover",
        useH5Prism: true,
        skinLayout: [],
      };
      this.player = new Aliplayer(Object.assign(initOpts, params));
    },
    agreePrivacy() {
      this.privacyDialogShow = false;
      this.startLive(true);
    },
    reload() {
      location.reload();
    },
    //上传附件
    uploadFile() {
      this.uploadfilevisiable = true;
    },
    uploadChange(file) {
      this.fileName = file.name;
      console.log(file);
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      if (!isXLSX && !isxlsx) {
        this.$message.error("只能上传后缀是.pdf或.PDF的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.NameList.push({
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileUrl: result.data.fileURL,
            liveCourseId: this.liveCourseId,
            liveLessonId: this.liveLessonId,
            fileSize: file.size,
            fileType: extension,
          });
          // this.ruleForm.chatFileKeys.push(
          //   result.data.fileKey + "_" + file.name
          // );
          console.log(this.NameList);
        })
        .catch((err) => {
          return;
        });
    },
    // 上传附件删除
    reomveExl(index) {
      this.NameList.splice(index, 1);
      this.$forceUpdate();
    },
    // 上传附件预览
    lookExl(fileUrl) {
      this.previewLoding = true;
      this.$nextTick(() => {
        pdf.embed(fileUrl, "#pdf-cert");
      });
    },
    // 上传取消
    fileClose() {
      this.uploadfilevisiable = false;
      this.NameList = [];
    },
    // 上传附件 - 确定
    uploadSure() {    
      if (this.NameList.length < 1) {
        this.$message({
          message: "请上传附件",
          type: "error",
        });
        return;
      }
      let parmar = {
        fileList: this.NameList,
      };
      this.$post("/lesson/file/insert", parmar, 3000, false, 3)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.uploadfilevisiable = false;
            this.NameList = [];
            this.activeName = "message";
            this.getuploadList();
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 已上传文件列表
    getuploadList() {
      let parmar = {
        liveCourseId: this.liveCourseId,
        liveLessonId: this.liveLessonId,
      };
      this.$post("/lesson/file/selectList", parmar, 3000, false, 3)
        .then((ret) => {
          if (ret.status == "0") {
            this.uploadFiles = ret.data || [];
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 查看
    lookPdf(fileUrl) {
      this.previewLoding = true;
      this.$nextTick(() => {
        pdf.embed(fileUrl, "#pdf-cert");
      });
    },
    chooseEmitQuestion() {
      this.$post(
        "/biz/live/question/v1/chosen",
        {liveLessonId: this.liveLessonId},
        3000,
        true,
        3
      ).then(re => {
        this.questionList = re.data;
        this.questionChosenVisible = true;
      }).catch(e => {
        this.answerVisible = true;
      })
    },
    cancelQuestionChosen() {
      this.questionChosenVisible = false;
      this.questionList = [];
    },
    ensureQuestionChosen() {
      let checked = this.questionList.filter(q => q.chosen).map(q => q.questionId);
      if (checked.length == 0) {
        this.$message({
          message: "请至少选择一项",
          type: "error",
        });
        return false;
      }
      this.$post("/biz/live/question/v1/emit", { liveLessonId: this.liveLessonId, questionIds: checked }, 3000, true, 3)
      .then((ret) => {
        if (ret.ok) {
          this.cancelQuestionChosen();
          this.answerVisible = true;
        }
      })
    },
    closeEmitQuestion() {
      this.$post("/biz/live/question/v1/close", { liveLessonId: this.liveLessonId }, 3000, true, 3)
      .then((ret) => {
        if (ret.ok) {
          this.answerVisible = false;
        }
      })
    }
  },
};
</script>
<style lang="less">
.el-upload-list__item {
  /deep/img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
.h3 {
  padding: 0.75rem 0;
}
</style>
<style lang="less" scope>
.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.framePage-halfBody .framePage-article {
  background-color: #fff;
  margin-left: 0.675rem;
  height: 100%;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
  width: 25%;
  min-width: 20rem;
  .title {
    display: flex;
    justify-content: space-between;
    position: relative;
    h3 {
      font-size: 0.85rem;
    }
    h3:before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -14px;
    }
    .title-button {
      padding: 0;
      font-size: 0.8rem;
    }
  }
  .searchBox {
    display: flex;
    margin: 15px 0;
  }
  .searchqusetionBox {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 50%;
      display: flex;
      margin: 15px 0;
      span {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
      .addclass {
        color: #4574f9;
      }
    }
  }
  .titleMation {
    display: flex;
    flex-direction: column;
    padding: 0.9rem 0 1.8rem 0;
    span:nth-child(2) {
      padding: 0.5rem 0;
    }
  }
  .btnBox {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        display: flex;
        justify-content: space-between;
        .number {
          padding-left: 1rem;
        }
        img {
          width: 100px;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: auto;
          display: flex;
          align-items: center;
          .qbimg {
            width: 5rem;
          }
        }
      }
    }
  }
}
.img-boxs {
  img {
    width: 100px;
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.informationmBox {
  display: flex;
  justify-content: space-between;
}
.el-divider--horizontal /deep/ {
  margin: 12px 0;
}
.el-cascader /deep/ {
  width: 100%;
}
.el-form-item__content /deep/ .el-upload {
  width: 100px;
  height: 148px !important;
}
.bannerBox .el-form-item__content /deep/ .df {
  display: flex;
}
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card /deep/.el-upload-list__item-actions {
  z-index: 9999;
}
.showbtn {
  display: none;
}
.hoverShow :hover {
  .showbtn {
    display: block;
  }
}
.liveBroadcastTitle {
  display: flex;
  li {
    flex: 1;
    text-align: center;
  }
}
.pztt {
  .times {
    margin-bottom: 20px;
  }
  .shell {
    border-bottom: 1px solid #1b2e4b;
    margin-bottom: 15px;
    p {
      line-height: 30px;
      span {
        margin-right: 20px;
      }
    }
  }
}
.upload-btn {
  width: 5rem;
  .el-upload {
    width: 5rem;
    height: auto !important;
    border: none !important;
  }
}
.tsColor1 {
  color: #37bf77;
}
</style>
